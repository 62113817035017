// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../../../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-browser-jsx": () => import("./../../../src/pages/browser.jsx" /* webpackChunkName: "component---src-pages-browser-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-linux-jsx": () => import("./../../../src/pages/linux.jsx" /* webpackChunkName: "component---src-pages-linux-jsx" */),
  "component---src-pages-mac-jsx": () => import("./../../../src/pages/mac.jsx" /* webpackChunkName: "component---src-pages-mac-jsx" */),
  "component---src-pages-terminal-jsx": () => import("./../../../src/pages/terminal.jsx" /* webpackChunkName: "component---src-pages-terminal-jsx" */),
  "component---src-pages-vs-code-jsx": () => import("./../../../src/pages/vs-code.jsx" /* webpackChunkName: "component---src-pages-vs-code-jsx" */),
  "component---src-pages-windows-jsx": () => import("./../../../src/pages/windows.jsx" /* webpackChunkName: "component---src-pages-windows-jsx" */)
}

